import React from "react"

import ContactForm from "../lib/components/ContactForm"
import Layout from "../lib/components/Layout"
import PageTitlePanel from '../lib/components/PageTitlePanel'
import Storage from '../lib/storage'

function Page({ location }) {

  // React hook for checking if screen is mobile size
  const [width, setWidth] = React.useState(null);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth)
    }

  }, []);
  const isMobile = width <= 768;

  return (
    <Layout
      title={Storage.pages.contact.metadata.title}
      description={Storage.pages.contact.metadata.description}
      pageUrl={location.pathname}
      bgNavSelector="titlePanel"
    >

      <PageTitlePanel
        title={Storage.pages.contact.titlePanel.title}
        backgroundImg={Storage.pages.contact.titlePanel.backgroundImg}
        isMobile={isMobile}
        relativeUrl={location.pathname}
      />

      <div className="relative w-full h-page-p v-page-p nexus-bg">
        <div className="flex flex-col gap-6">
          <h1 className="font-bold uppercase nexus-text">How you can contact us</h1>
          <div className="flex flex-col gap-6">
            {
              Storage.global.contactItems.map((item) => {
                return (
                  <a key={item.link} href={item.link} className="cursor-pointer flex items-center gap-4 group p-2 rounded-md hover:bg-neutral-300/50 dark:hover:bg-slate-600">
                    <div className="w-6 h-6 rounded-full">{item.icon}</div>
                    <p className="nexus-text group-hover:font-bold">{item.text}</p>
                  </a>
                )
              })
            }
          </div>
        </div>
      </div>

      <div className="relative w-full h-page-p v-page-p nexus-bg">
        <div className="flex flex-col gap-4">
          <div className="grid gap-4">
            <h1 className="font-bold uppercase nexus-text">Enquiries Form</h1>
            <p className="font-normal nexus-text">Submit your enquiry below and we will reach out to you directly.</p>
          </div>
          <ContactForm />
        </div>
      </div>

    </Layout>
  )
}

export default Page
